import Layout from "../components/layout";
import React from "react"
function Events(){
    return(
        <Layout>
            Coming soon!
        </Layout>
    )
}

export default Events